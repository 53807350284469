import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import ApiService from 'src/services/api.service';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'dar-diwan-world.firebaseapp.com',
  projectId: 'dar-diwan-world',
  storageBucket: 'dar-diwan-world.appspot.com',
  messagingSenderId: '130593924198',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const storeTokenInDB = async ({ userId, token, setTokenInfo }) => {
  try {
    if (userId && token) {
      await ApiService.patch(`users/${userId}`, {
        web_push_notification_token: token,
      });
    }
  } catch (error) {
    console.log(error);
    setTokenInfo({
      isFound: false,
      error: 'savingPushNotificationTokenError',
      alert: true,
    });
  }
};

export const askForPermissioToReceiveNotifications = async ({
  setTokenInfo,
  userId,
}) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission) {
      const currentToken = await getToken(messaging, {
        vapidKey: process?.env.REACT_APP_VAPIDKEY,
      });

      if (currentToken) {
        setTokenInfo({
          isFound: true,
          error: null,
          alert: true,
        });
        // console.log(currentToken);
        // post to DB
        await storeTokenInDB({ userId, token: currentToken, setTokenInfo });
      } else {
        setTokenInfo({
          isFound: false,
          error: 'generatingPushNotificationTokenError',
          alert: true,
        });
      }
    } else {
      setTokenInfo({
        isFound: false,
        error: 'pushNotificationPermissionError',
        alert: true,
      });
    }
  } catch (error) {
    console.log(error);
    setTokenInfo({
      isFound: false,
      error: 'pushNotificationTokenError',
      alert: true,
    });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
