import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCouponAction } from 'src/store/actions/coupon.actions';
import { SET_CHECKOUT_PAYLOAD } from 'src/store/constants/cart.types';
import ContentCard from './ContentCard';

const useClasses = makeStyles((theme) => ({
  couponTitle: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  checkIcon: {
    color: ({ enteredCoupon }) =>
      !enteredCoupon ? theme.custom.myTheme.grey3 : theme.palette.success.main,
  },
  textField: {
    background: 'white',
    fontFamily: 'sfUiDisplayMedium',
    color: theme.palette.primary.main,
    width: '100%',
    padding: '4px 2px',
    '& input': {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    '& ::placeholder': {
      color: theme.custom.myTheme.grey3,
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
  placeholder: {
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
}));

export default function Coupon() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [enteredCoupon, setEnteredCoupon] = useState('');
  const { userInfo } = useSelector((state) => state.authReducer);
  const { cartItems, checkoutPayload } = useSelector(
    (state) => state.cartReducer
  );
  const { coupon } = useSelector((state) => state.couponReducer);
  const { currencyInfo } = useSelector((state) => state.userReducer);

  const { otherTotal } = checkoutPayload;

  const classes = useClasses({ enteredCoupon });

  const validateCoupon = () => {
    if (enteredCoupon?.trim().length) {
      // setDiscountValue(0);
      // setResetShipping(true);

      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          // ...checkoutPayload,
          discountValue: 0,
          resetShipping: true,
        },
      });

      dispatch(getCouponAction(enteredCoupon));
    }
  };

  useEffect(() => {
    if (coupon && coupon.length > 0) {
      let isValid = false;
      const couponInfo = coupon[0];
      if (
        !coupon.length ||
        couponInfo.orders.find(
          (item) =>
            item.customer_id == userInfo &&
            (item.payment_status == 'success' ||
              item.payment_status == 'full_discount')
        ) ||
        couponInfo.user_max_num <= couponInfo.orders?.length ||
        couponInfo.start_date > new Date().toISOString().slice(0, 10) ||
        couponInfo.exp_date < new Date().toISOString().slice(0, 10)
      ) {
        console.log(
          'you have already used this coupon before / invalid coupon'
        );
        return;
      }

      let discount = 0;
      // let discount = total;
      let otherDiscount = otherTotal;
      let couponVal = 0;

      cartItems.forEach((element) => {
        if (couponInfo.product_id == element.product.id) {
          isValid = true;
          if (couponInfo.value) {
            discount += couponInfo.value * currencyInfo.from * element.quantity;
            // discount -= couponInfo.value * currencyInfo.from * element.quantity;
            otherDiscount -= couponInfo.value * element.quantity;
            couponVal +=
              couponInfo.value * currencyInfo.from * element.quantity;
            // setIsPersentage(false);
            dispatch({
              type: SET_CHECKOUT_PAYLOAD,
              payload: {
                // ...checkoutPayload,
                isPersentage: false,
              },
            });
          } else if (couponInfo.persentage) {
            dispatch({
              type: SET_CHECKOUT_PAYLOAD,
              payload: {
                // ...checkoutPayload,
                isPersentage: true,
              },
            });
            // setIsPersentage(true);
            couponVal = couponInfo.persentage;
            discount +=
              (couponInfo.persentage *
                element.product.discounted_price *
                currencyInfo.from *
                element.quantity) /
              100;

            otherDiscount -=
              (couponInfo.persentage *
                element.product.discounted_price *
                element.quantity) /
              100;
          }
          console.log('helal olsun 1');
          return;
        } else {
          if (
            element.product.categories.find(
              (item) => item.category_id == couponInfo.category_id?.id
            ) ||
            element.product.tags.find(
              (item) => item.tag_id == couponInfo.tag_id?.id
            )
          ) {
            isValid = true;
            if (couponInfo.value) {
              discount +=
                couponInfo.value * currencyInfo.from * element.quantity;
              // discount -=
              //   couponInfo.value * currencyInfo.from * element.quantity;
              otherDiscount -= couponInfo.value * element.quantity;
              couponVal +=
                couponInfo.value * currencyInfo.from * element.quantity;
              dispatch({
                type: SET_CHECKOUT_PAYLOAD,
                payload: {
                  // ...checkoutPayload,
                  isPersentage: false,
                },
              });
              // setIsPersentage(false);
              console.log('discount 1', discount);
            } else if (couponInfo.persentage) {
              // setIsPersentage(true);
              dispatch({
                type: SET_CHECKOUT_PAYLOAD,
                payload: {
                  // ...checkoutPayload,
                  isPersentage: true,
                },
              });
              couponVal = couponInfo.persentage;
              discount +=
                (couponInfo.persentage *
                  element.product.discounted_price *
                  currencyInfo.from *
                  element.quantity) /
                100;

              otherDiscount -=
                (couponInfo.persentage *
                  element.product.discounted_price *
                  element.quantity) /
                100;
            }
            return false;
          } else {
            console.log('invalid coupon');
          }
        }
        return true;
      });

      if (couponInfo.free_shipping) {
        dispatch({
          type: SET_CHECKOUT_PAYLOAD,
          payload: {
            shipping: 0,
            otherShipping: 0,
          },
        });
      }

      dispatch({
        type: SET_CHECKOUT_PAYLOAD,
        payload: {
          discountValue: discount,
          otherTotal: otherDiscount,
          couponCode: couponInfo.code,
          couponValue: couponVal,
          couponId: couponInfo.id,
          validCode: isValid,
        },
      });
    }
  }, [coupon, cartItems]);

  return (
    <ContentCard>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Typography color="primary" className={classes.couponTitle}>
            {t('coupon')}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            label={t('enterYourCode')}
            placeholder={t('enterYourCode')}
            variant="outlined"
            value={enteredCoupon}
            onChange={(e) => setEnteredCoupon(e.target.value)}
            margin="dense"
            InputLabelProps={{ className: classes.placeholder }}
            style={{ direction: 'ltr' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={validateCoupon}
                    size="small"
                    style={{
                      backgroundColor: 'transparent',
                      borderRadius: 8,
                      border: '1px solid #AAA',
                      padding: '6px 10px',
                    }}
                  >
                    <Typography variant="body2">{t('apply')}</Typography>
                    {/* <Check className={classes.checkIcon} fontSize="small" /> */}
                  </IconButton>
                </InputAdornment>
              ),

              className: classes.textField,
            }}
          />
        </Grid>
      </Grid>
    </ContentCard>
  );
}
